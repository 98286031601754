'use client'
/**
 * MW Design System - Typography Component
 * https://mercury.design/system?p=typography
 * https://www.figma.com/file/S7Q78jkg0O92CodjYielQO/%F0%9F%92%BB-Design-System---Web?node-id=7%3A126
 */
import classnames from 'classnames'
import React, {forwardRef, type HTMLElementType, useState} from 'react'
import {type OmitStrict} from 'type-zoo'
import styles from './styles.module.css'
import {colorNameToColorClass} from '~/mercuryWebCompat/tokens/colors/utils'
import {type BaseColorName} from '~/mercuryWebCompat/tokens/colors/baseColors'
import {type SemanticColorName} from '~/mercuryWebCompat/tokens/colors/semanticColors'

export type TextSize =
  | (typeof allSizes)[number]
  | (typeof allDeprecatedSizes)[number]

/** For convenience in e.g. showing all sizes in Storybook */
export const allSizes = [
  // Headers, largest to smallest
  'title-hero', // formerly h1
  'title-hero-md', // updated md styles
  'title-main', // formerly h2
  'title-main-md', // updated md styles
  'title-secondary', // formerly subheader
  'title-minor',
  'title-eyebrow', // formerly section-title
  // Body text, largest to smallest
  'body-large', // formerly body-17
  'body-large-demi', // formerly body-17-demi
  'body-default', // formerly body-15
  'body-default-md',
  'body-default-demi', // formerly body-15-demi
  'body-secondary', // formerly caption
  'body-secondary-demi', // formerly caption-demi
  'label',
  'label-demi',
  'tiny',
  'tiny-demi',
  'cent',
  'micro',
  'micro-demi',
] as const

/** Deprecated text sizes */
export const allDeprecatedSizes = [
  'h3', // not currently in DS but markdown renderer uses it
  'body-16',
  'body-16-demi',
] as const

type GenericMWDSTextProps = {
  size: TextSize
  color?: BaseColorName | SemanticColorName
  hoverColor?: BaseColorName
  underline?: boolean
  bold?: boolean
  italic?: boolean
  /* Add a ... if the text exceeds the width of its parent container */
  ellipsis?: boolean
}

export type MWDSTextProps<MWDSTextElement extends HTMLElement = HTMLSpanElement> = {
  // The default is 'span'.
  tag?: HTMLElementType
} & GenericMWDSTextProps &
  OmitStrict<React.AllHTMLAttributes<MWDSTextElement>, 'color' | 'size'>

function MWDSTextInner<MWDSTextElement extends HTMLElement = HTMLSpanElement>(
  props: MWDSTextProps<MWDSTextElement>,
  ref: React.ForwardedRef<MWDSTextElement>
) {
  const [hovered, setHovered] = useState(false)

  const {
    size,
    color,
    hoverColor,
    underline,
    bold,
    italic,
    ellipsis,
    className,
    tag = 'span',
    ...elementProps
  } = props

  // don't default to 'black' here, we do that in global.css
  // instead, allow users to use the cascade to set colors if necessary/nice
  const colorToUse = (hovered && hoverColor) || color
  return React.createElement(tag, {
    ...elementProps,
    ref,
    onMouseEnter: (e: React.MouseEvent<MWDSTextElement>) => {
      setHovered(true)
      elementProps.onMouseEnter?.(e)
    },
    onMouseLeave: (e: React.MouseEvent<MWDSTextElement>) => {
      setHovered(false)
      elementProps.onMouseLeave?.(e)
    },
    className: classnames(
      styles[size],
      underline && styles.underline,
      bold && styles['force-demi'],
      italic && styles.italic,
      ellipsis && styles.ellipsis,
      colorToUse && colorNameToColorClass(colorToUse),
      className
    ),
  })
}

const MWDSText = forwardRef(MWDSTextInner)
export default MWDSText

export type MWDSTextDivProps = OmitStrict<MWDSTextProps<HTMLDivElement>, 'tag'>

export const MWDSTextDiv = forwardRef<HTMLDivElement, MWDSTextDivProps>(
  (props, ref) => <MWDSText {...props} ref={ref} tag="div" />
)
MWDSTextDiv.displayName = 'MWDSTextDiv'

type HeaderProps = OmitStrict<MWDSTextDivProps, 'size'>

export class SectionName extends React.Component<HeaderProps> {
  render() {
    return (
      <MWDSTextDiv
        size="title-eyebrow"
        className={classnames(this.props.className, styles['section-name-el'])}
        {...this.props}
      >
        {this.props.children}
      </MWDSTextDiv>
    )
  }
}
